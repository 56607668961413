import { SplitView } from '@/components/SplitView';
import { LAYER_FORMAT_TYPE } from '@/constants/layer';
import { useAnnotationTemplates } from '@/hooks/useAnnotationTemplates';
import { useFetchAnnotations } from '@/hooks/useFetchAnnotations';
import { useFetchPhotos } from '@/hooks/useFetchPhotos';
import { useFetchProcesses } from '@/hooks/useFetchProcesses';
import { useFetchSceneEntities } from '@/hooks/useFetchSceneEntities';
import { startUpdateAnnotation, useExplore } from '@/stores/explore';
import { useLayout } from '@/stores/layout';
import {
  Layer,
  Panorama,
  Photo2D,
  ViewerState,
  addViewerEventListener,
  disableViewerGlobeUI,
  enableViewerGlobeUI,
  loadLayerModel,
  removeViewerEventListener,
  resolvePhotoGroup,
  setImageViewerSettings,
  setImageViewerTab,
  setTargetMeasurement,
  setTargetPhoto,
  setViewerAPI2D,
  startPanoramaWalkthrough,
  useViewer,
  enableViewer2D,
  updatePanoramaImageUrls,
  LayerGroup,
} from '@/stores/viewer';
import { persist } from '@/utils/Persist';
import { Sketch2 } from '@/utils/Viewer2D';
import { cn } from '@/utils/classname';
import { sortAndFilterPhotos } from '@/utils/photos';
import {
  CameraMotionCallback,
  CursorListener,
  GlobeMode,
  Imagery,
  ImageryBaseMap,
  Model,
  ModelNode,
  Panorama as PanoramaModel,
  PhotoCamera,
  SceneNode,
  Sketch,
  Terrain,
  Tileset,
} from '@skand/viewer-component-v2';
import { CSSProperties, useEffect, useRef } from 'react';
import { Color, Vector3 } from 'three';
import { ImageViewerControls } from './ImageViewerControls';
import { Viewer2D } from './Viewer2D';
import { Viewer3D } from './Viewer3D';
import { DataAttributionModal } from '@/components/DataAttributionModal';
import { DraggableMenu } from '@/components/DraggableMenu';
import { useTreatments } from '@splitsoftware/splitio-react';
import { MULTITHREADING_OBJ } from '@/utils/split';

export const Viewer = ({ style }: { style?: CSSProperties }) => {
  const initialCameraCallback = useRef(true);
  const initialCameraOverride = useRef(true);
  const initialImageOverride = useRef(true);

  const isShowingLeftSideBarI = useLayout(state => state.isShowingLeftSideBarI);
  const isShowingRightSideBar = useLayout(state => state.isShowingRightSideBar);
  const showLocationAlertModal = useExplore(state => state.showLocationAlertModal);
  const enabled2D = useViewer(state => state.enabled2D);
  const enabledDraw2D = useViewer(state => state.enabledDraw2D);
  const enabledDraw3D = useViewer(state => state.enabledDraw3D);
  const enabledPanoramaWalkthrough = useViewer(state => state.enabledPanoramaWalkthrough);
  const enabledDataAttributionModal = useViewer(state => state.enabledDataAttributionModal);

  const api3D = useViewer(state => state.api3D);
  const api2D = useViewer(state => state.api2D);
  const layers = useViewer(state => state.layers);

  const annotationGroups = useViewer(state => state.annotationGroups);
  const photo2DGroups = useViewer(state => state.photo2DGroups);
  const panoramaGroups = useViewer(state => state.panoramaGroups);
  const targetPhoto = useViewer(state => state.targetPhoto);
  const targetAnnotation3D = useViewer(state => state.targetAnnotation3D);
  const targetProcess = useViewer(state => state.targetProcess);

  const globeMode = useViewer(state => state.globeMode);
  const baseMapType = useViewer(state => state.baseMapType);
  const navigationMode = useViewer(state => state.navigationMode);
  const projectionMode = useViewer(state => state.projectionMode);

  const measurements = useViewer(state => state.measurements);
  const visibleLayers = useViewer(state => state.visibleLayers);
  const visibleAnnotations = useViewer(state => state.visibleAnnotations);
  const filteredLayers = useViewer(state => state.filteredLayers);
  const filteredAnnotations = useViewer(state => state.filteredAnnotations);

  const viewerSettings = useViewer(state => state.viewer3DSettings);
  const panoramaViewerSettings = useViewer(state => state.panoramaViewerSettings);
  const photo2DViewerSettings = useViewer(state => state.photo2DViewerSettings);
  const tilesetSettings = useViewer(state => state.tilesetSettings);
  const measurementUnit = useExplore(state => state.measurementUnit);

  const listeners = useViewer(state => state.listeners);
  const imageViewerTab = useViewer(state => state.imageViewerTab);
  const hotkeys = useExplore(state => state.hotkeys);
  const annotationDraft = useExplore(state => state.annotationDraft);
  const epsg = useExplore(state => state.epsg);

  // Get fetch handlers
  const fetchSceneEntities = useFetchSceneEntities();
  const { fetch: fetchAnnotations } = useFetchAnnotations();
  const { fetch: fetchPhotos } = useFetchPhotos();
  const { response: queryAnnotationTemplates } = useAnnotationTemplates();
  const fetchProcesses = useFetchProcesses();

  // Feature flags
  const treatment = useTreatments([MULTITHREADING_OBJ]);
  const multithreadingObjFlag = treatment[MULTITHREADING_OBJ].treatment === 'on';

  // Set the initial camera position and annotation select from persist storage
  useEffect(() => {
    if (!api3D || !initialCameraOverride.current) return;

    const persistAnnotation = persist.get('annotation');
    const persistCameraPosition = persist.get('cameraPosition');
    const persistCameraRotation = persist.get('cameraRotation');
    const persistCameraState = persist.get('orthoMatrix');

    const annotation = annotationGroups
      .flatMap(group => group.annotations)
      .find(annotation => annotation.id === persistAnnotation);

    if (persistCameraPosition && persistCameraRotation) {
      api3D.navigation.moveTo(persistCameraPosition, persistCameraRotation);
      if (persistCameraState) {
        api3D.navigation.setOrthoMatrixParams(persistCameraState);
      }
      if (annotation?.sketch3D) {
        startUpdateAnnotation(annotation.metadata, annotation.template);
        initialCameraOverride.current = false;
      }
    } else if (persistAnnotation) {
      if (annotation?.sketch3D) {
        api3D.navigation.lookAt(annotation.sketch3D, false);
        startUpdateAnnotation(annotation.metadata, annotation.template);
        initialCameraOverride.current = false;
      }
    } else {
      initialCameraOverride.current = false;
    }
  }, [annotationGroups, api3D]);

  // Update persist storage
  useEffect(() => {
    persist.set('layers', [...visibleLayers]);
    if (annotationDraft?.annotationId) {
      persist.set('annotation', annotationDraft.annotationId);
    }

    // Persist image if both image viewer is opened and target photo is selected.
    if (targetPhoto?.id && enabled2D) {
      persist.set('image', targetPhoto.id);
    }

    persist.set('globe', globeMode);
    persist.set('imageryBase', baseMapType);
    persist.set('navigation', navigationMode);
    persist.set('projection', projectionMode);

    // Save the camera parameters together with the current projection mode
    if (api3D) {
      const state = api3D.navigation.getOrthoMatrixParams();
      persist.set('orthoMatrix', state);
    }

    persist.set('panoramaIconSize', viewerSettings.panoramaIconSize);
    persist.set('panoramaCameraHeight', viewerSettings.panoramaCameraHeight);
    persist.set('backgroundColor', viewerSettings.backgroundColor);
    persist.set('maxNetworkRequests', viewerSettings.maxNetworkRequests);
    persist.set('networkRequestSorting', viewerSettings.networkRequestSorting);
    persist.set('tileRequestCancelling', viewerSettings.tileRequestCancelling);
    persist.set('localCacheEnabled', viewerSettings.localCacheEnabled);
    persist.set('tileMemoryBudget', viewerSettings.tileMemoryBudget);
    persist.set('showLabels', viewerSettings.annotationNameVisibility);
    persist.set('showMeasurements', viewerSettings.annotationMeasurementVisibility);
    persist.set('edl', viewerSettings.eyeDomeLighting);
    persist.set('pointSizeAttenuation', viewerSettings.pointSizeAttenuation);
    persist.set('orthoNearPlaneClipping', viewerSettings.orthoNearPlaneClipping);
    persist.set('globeClipping', viewerSettings.globeClipping);
    persist.set('srs', epsg);

    const persistTilesetSettings = persist.get('tilesetSettings') ?? {};
    for (const [layerId, settings] of tilesetSettings) {
      persistTilesetSettings[layerId] = settings;
    }
    persist.set('tilesetSettings', persistTilesetSettings);
    persist.url.refreshURL();
  }, [
    annotationDraft,
    api3D,
    baseMapType,
    globeMode,
    navigationMode,
    projectionMode,
    tilesetSettings,
    viewerSettings,
    visibleLayers,
    epsg,
    targetPhoto,
    enabled2D,
  ]);

  // Resolve fetched layers
  const resolveLayers = (
    state: ViewerState,
    layers: Layer[],
    layerGroups: LayerGroup[],
  ): Partial<ViewerState> => {
    const oldLayersMap = new Map(state.layers.map(layer => [layer.id, layer]));
    const newLayersMap = new Map(layers.map(layer => [layer.id, layer]));

    // Destroy old layers
    for (const [id, oldLayer] of oldLayersMap) {
      if (
        !newLayersMap.has(id) &&
        (!(oldLayer.sceneNode instanceof SceneNode) || oldLayer.sceneNode instanceof ModelNode)
      ) {
        oldLayersMap.delete(id);
        oldLayer.sceneNode.destroy();
      }
    }

    // Sort layers by capture date
    layers.sort((a, b) => b.captureDate.getDate() - a.captureDate.getDate());

    // Open default layer if no layers are open, making sure all layer ids are valid
    const visibleLayers = new Set(state.visibleLayers);
    if (layers.length) {
      for (const layerId of visibleLayers) {
        const existLayer = layers.find(layer => layer.id === layerId);
        const existLayerGroup = layerGroups.find(layerGroup => layerGroup.id === layerId);
        if (!existLayer && !existLayerGroup) {
          visibleLayers.delete(layerId);
        }
      }
    }
    for (let i = 0; i < layers.length && visibleLayers.size === 0; i++) {
      if (
        layers[i].formatType === LAYER_FORMAT_TYPE.MESH_3D ||
        layers[i].formatType === LAYER_FORMAT_TYPE.POINT_CLOUD ||
        layers[i].formatType === LAYER_FORMAT_TYPE.BIM_CAD_MODEL ||
        layers[i].formatType === LAYER_FORMAT_TYPE.ORTHO_2D
      ) {
        visibleLayers.add(layers[i].id);
      }
    }
    if (visibleLayers.size === 0 && layers.length) {
      visibleLayers.add(layers[0].id);
    }

    return { layers, visibleLayers };
  };

  // Destroy previous Annotations before new annotations get created.
  const destroyPreviousAnnotations = (prevState: ViewerState) => {
    for (const group of prevState.annotationGroups) {
      for (const annotation of group.annotations) {
        if (prevState.visibleAnnotations.has(annotation.id)) {
          annotation.sketch3D?.destroy();
          annotation.sketch2D?.destroy();
        }
      }
    }
  };

  // Fetch scene entities and update viewer state
  useEffect(() => {
    fetchSceneEntities().then(viewerState => {
      useViewer.setState(prevState => {
        const resolvedLayers = resolveLayers(
          prevState,
          viewerState.layers,
          viewerState.layerGroups,
        );
        destroyPreviousAnnotations(prevState);
        return {
          ...viewerState,
          ...resolvedLayers,
        };
      });
    });
  }, [fetchSceneEntities]);

  // Fetch photos and update viewer state
  useEffect(() => {
    if (
      api2D &&
      api3D &&
      (photo2DGroups.some(group => group.loadState === 'pending') ||
        panoramaGroups.some(group => group.loadState === 'pending'))
    ) {
      fetchPhotos().map(async result => {
        const { newCameras, newGroup } = await result;
        resolvePhotoGroup(newGroup, newCameras);
      });
    }
  }, [api2D, api3D, fetchPhotos, panoramaGroups, photo2DGroups]);

  // Fetch annotations and update viewer state
  useEffect(() => {
    if (
      api2D &&
      api3D &&
      queryAnnotationTemplates.isSuccess &&
      annotationGroups.some(group => group.loadState === 'pending')
    ) {
      fetchAnnotations(annotationGroups);
    }
  }, [annotationGroups, api2D, api3D, fetchAnnotations, queryAnnotationTemplates.isSuccess]);

  // Fetch processes and update viewer state
  useEffect(() => {
    fetchProcesses().then(processes => {
      useViewer.setState(prevState => {
        const currTarget = prevState.targetProcess;
        const targetProcess = currTarget
          ? processes.find(process => process.id === currTarget.id)
          : null;
        return { processes, targetProcess };
      });
    });
  }, [fetchProcesses]);

  // Toggle globe map modes
  useEffect(() => {
    const cb = async () => {
      if (api3D) {
        const currentBaseMapType: ImageryBaseMap = api3D.globe.getImageryBaseMap();
        if (currentBaseMapType !== baseMapType) {
          disableViewerGlobeUI();
          await api3D.globe.setImageryBaseMap(baseMapType);
          enableViewerGlobeUI();
        }

        const currentGlobeMode: GlobeMode = api3D.globe.getGlobeMode();
        if (currentGlobeMode !== globeMode) {
          disableViewerGlobeUI();
          await api3D.globe.setGlobeMode(globeMode);
          enableViewerGlobeUI();
        }
      }
    };
    cb();
  }, [api3D, baseMapType, globeMode]);

  // Toggle visibility of layers
  useEffect(() => {
    const toggle = async () => {
      disableViewerGlobeUI();
      for (const layer of layers) {
        if (visibleLayers.has(layer.id) && !filteredLayers.has(layer.id)) {
          // Show the model and load if not loaded
          if (
            (layer.sceneNode instanceof ModelNode ||
              layer.sceneNode instanceof Terrain ||
              layer.sceneNode instanceof Imagery) &&
            !layer.sceneNode.isVisible()
          ) {
            await layer.sceneNode.show();
          } else if (layer.sceneNode.constructor === SceneNode) {
            const error = await loadLayerModel(layer);

            // Update layer filters in image viewer
            const photoGroupIds = [];

            for (const group of [...photo2DGroups, ...panoramaGroups]) {
              let node = group.parent;
              while (node) {
                if (visibleLayers.has(node.id)) {
                  photoGroupIds.push(group.id);
                  break;
                }
                node = node.parent;
              }
            }
            setImageViewerSettings({ photoGroupIds }, 'photo2D');
            setImageViewerSettings({ photoGroupIds }, 'panorama');

            if (error) {
              useExplore.setState({ placeLayerTarget: layer });
              showLocationAlertModal();
            }
          }

          // Update globe mode to imegery if layer is Imagery and selected globe mode is default
          if (layer.sceneNode instanceof Imagery && globeMode === 'default') {
            useViewer.setState({ globeMode: 'imagery' });

            // Update globe mode to terrain if layer is Terrain and selected globe mode is not terrian
          } else if (layer.sceneNode instanceof Terrain && globeMode !== 'terrain') {
            useViewer.setState({ globeMode: 'terrain' });
          }
        } else if (
          (layer.sceneNode instanceof ModelNode ||
            layer.sceneNode instanceof Terrain ||
            layer.sceneNode instanceof Imagery) &&
          layer.sceneNode.isVisible()
        ) {
          await layer.sceneNode.hide();
        }
      }

      // Update layer filters in image viewer when image viewer is turned off
      if (!enabled2D) {
        const photoGroupIds = [];
        for (const group of [...photo2DGroups, ...panoramaGroups]) {
          let node = group.parent;

          // Root level image group should be included automatically
          if (!node) {
            photoGroupIds.push(group.id);
          }

          // Otherwise, recursively check parent
          while (node) {
            if (visibleLayers.has(node.id)) {
              photoGroupIds.push(group.id);
              break;
            }
            node = node.parent;
          }
        }
        setImageViewerSettings({ photoGroupIds }, 'photo2D');
        setImageViewerSettings({ photoGroupIds }, 'panorama');
      }

      enableViewerGlobeUI();
    };
    toggle();
  }, [
    filteredLayers,
    layers,
    showLocationAlertModal,
    visibleLayers,
    api3D,
    photo2DGroups,
    panoramaGroups,
    globeMode,
    enabled2D,
  ]);

  // Toggle visibility of 3D annotations
  useEffect(() => {
    for (const group of annotationGroups) {
      for (const annotation of group.annotations) {
        // Hide target annotation currently being edited
        if (
          visibleAnnotations.has(annotation.id) &&
          !filteredAnnotations.has(annotation.id) &&
          annotation !== targetAnnotation3D
        ) {
          annotation.sketch3D?.show();
        } else {
          annotation.sketch3D?.hide();
        }
      }
    }
  }, [annotationGroups, filteredAnnotations, targetAnnotation3D, visibleAnnotations]);

  // Toggle visibility of 3D measurements
  useEffect(() => {
    for (const measurement of measurements) {
      measurement.sketch.showMeasurements();
      measurement.sketch.show();
    }
  }, [measurements]);

  // Register explore-defined event listeners to the viewer
  useEffect(() => {
    const models: Model[] = [];
    for (const layer of layers) {
      if (!(layer.sceneNode instanceof SceneNode && !(layer.sceneNode instanceof ModelNode))) {
        models.push(layer.sceneNode);
      }
    }
    for (const group of annotationGroups) {
      for (const annotation of group.annotations) {
        if (annotation.sketch3D) {
          models.push(annotation.sketch3D);
        }
      }
    }
    for (const group of photo2DGroups) {
      for (const photo of group.photos) {
        if (photo.widget) {
          models.push(photo.widget);
        }
      }
    }
    for (const group of panoramaGroups) {
      for (const photo of group.photos) {
        if (photo.widget) {
          models.push(photo.widget);
        }
      }
    }
    for (const measurement of measurements) {
      models.push(measurement.sketch);
    }

    api3D?.utility.setCursorHoverCallback((position, model, userData) => {
      for (const handler of listeners.onCursorHover) {
        handler(position, model, userData);
      }
    }, models);

    api3D?.utility.setCursorTapCallback((position, model, userData) => {
      for (const handler of listeners.onCursorTap) {
        handler(position, model, userData);
      }
    }, models);

    api3D?.navigation.setCameraMotionCallback((position, rotation, params) => {
      for (const handler of listeners.onCameraMotion) {
        handler(position, rotation, params);
      }
    });

    api3D?.navigation.setCameraStopCallback((position, rotation, params) => {
      for (const handler of listeners.onCameraMotionFinished) {
        handler(position, rotation, params);
      }
    });

    api2D?.editor.setListener(sketch => {
      for (const handler of listeners.onSketch2DTap) {
        handler(sketch);
      }
    });
  }, [
    annotationGroups,
    api2D,
    api3D,
    enabledPanoramaWalkthrough,
    layers,
    listeners,
    measurements,
    panoramaGroups,
    panoramaViewerSettings.lockSorting,
    panoramaViewerSettings.sortMode,
    photo2DGroups,
  ]);

  // Enable 2D Viewer and Panorama with sharelink image
  useEffect(() => {
    const showDefaultPhoto = async () => {
      if (!initialImageOverride.current) return;
      for (const group of photo2DGroups) {
        const photo = group.photos.find(photo => photo.id === persistImage) as Photo2D;
        if (photo) {
          initialImageOverride.current = false;
          setImageViewerTab('photo2D');
          setTargetPhoto(photo);
          enableViewer2D();
          break;
        }
      }
      for (const group of panoramaGroups) {
        const photo = group.photos.find(photo => photo.id === persistImage) as Panorama;
        if (photo) {
          initialImageOverride.current = false;
          await updatePanoramaImageUrls(photo);
          if (photo.widget) {
            api3D?.panorama
              .getWalkthrough()
              .setPanoramas([photo.widget.getModel() as PanoramaModel]);
          }
          setImageViewerTab('panorama');
          setTargetPhoto(photo);
          startPanoramaWalkthrough(photo);
          break;
        }
      }
    };
    const persistImage = persist.get('image');
    if (persistImage) {
      showDefaultPhoto();
    }
  }, [api3D, panoramaGroups, photo2DGroups]);

  // Apply 3D viewer settings
  useEffect(() => {
    api3D?.draw.setMeasurementUnit(measurementUnit);
    api3D?.utility.setNetworkRequestSortingEnabled(viewerSettings.networkRequestSorting);
    api3D?.utility.setRequestCancellingEnabled(viewerSettings.tileRequestCancelling);
    api3D?.utility.setShowTilePrioritiesEnabled(viewerSettings.showRequestPriorities);
    api3D?.utility.setMaxNetworkRequestsInFlight(viewerSettings.maxNetworkRequests);
    api3D?.utility.setCacheEnabled(viewerSettings.localCacheEnabled);
    api3D?.utility.setBackgroundColor(viewerSettings.backgroundColor);
    api3D?.navigation.setOrthoNearPlaneClipping(viewerSettings.orthoNearPlaneClipping);
    api3D?.utility.setEyeDomeLightingEnabled(viewerSettings.eyeDomeLighting);
    api3D?.globe.setGlobeClipping(viewerSettings.globeClipping);
    api3D?.utility.setMultithreadedOBJEnabled(multithreadingObjFlag);
    for (const layer of layers) {
      if (layer.sceneNode instanceof ModelNode) {
        const model = layer.sceneNode.getModel();
        if (model instanceof Tileset) {
          const style = model.getStyle();
          style.pointSizeAttenuation = viewerSettings.pointSizeAttenuation;
          model.setMemoryBudget(viewerSettings.tileMemoryBudget * (1024 * 1024));
          model.setStyle(style);
        }
      }
    }
    for (const group of panoramaGroups) {
      for (const photo of group.photos) {
        if (photo.widget) {
          photo.widget.setScale(
            new Vector3(
              viewerSettings.panoramaIconSize,
              viewerSettings.panoramaIconSize,
              viewerSettings.panoramaIconSize,
            ),
          );
          const panorama = photo.widget.getModel() as PanoramaModel;
          panorama.setOpacity360(viewerSettings.overlayOpacity);
          panorama.setCameraHeight(viewerSettings.panoramaCameraHeight);
        }
      }
    }
    for (const group of annotationGroups) {
      for (const annotation of group.annotations) {
        if (annotation.sketch3D instanceof ModelNode) {
          const sketch3D = annotation.sketch3D.getModel() as Sketch;
          sketch3D.setMeasurementUnit(measurementUnit);

          const shouldShowName =
            viewerSettings.annotationNameVisibility &&
            (!annotationDraft || annotation.id === annotationDraft.annotationId);
          const shouldShowMeasurements =
            viewerSettings.annotationMeasurementVisibility &&
            (!annotationDraft || annotation.id === annotationDraft.annotationId);

          if (shouldShowName) {
            sketch3D.showName();
          } else {
            sketch3D.hideName();
          }

          if (shouldShowMeasurements) {
            sketch3D.showMeasurements();
          } else {
            sketch3D.hideMeasurements();
          }
        }
      }
    }
  }, [
    annotationGroups,
    api3D,
    layers,
    measurementUnit,
    panoramaGroups,
    viewerSettings,
    multithreadingObjFlag,
    annotationDraft,
  ]);

  // Sort and filter 2D photos
  useEffect(() => {
    if (targetProcess || (enabled2D && imageViewerTab === 'photo2D')) {
      sortAndFilterPhotos(
        photo2DGroups,
        photo2DViewerSettings,
        annotationDraft === null,
        targetProcess,
      ).then(photos => {
        useViewer.setState({ filteredPhoto2Ds: photos as Photo2D[] });
      });
    } else {
      for (const group of photo2DGroups) {
        for (const photo of group.photos) {
          photo.widget?.hide();
        }
      }
    }
  }, [
    annotationDraft,
    photo2DViewerSettings,
    photo2DGroups,
    enabled2D,
    imageViewerTab,
    targetProcess,
  ]);

  // Sort and filter panoramas
  useEffect(() => {
    if ((enabled2D && imageViewerTab === 'panorama') || enabledPanoramaWalkthrough || !enabled2D) {
      const canShow =
        annotationDraft === null &&
        ((enabled2D && imageViewerTab === 'panorama') || enabledPanoramaWalkthrough);
      sortAndFilterPhotos(panoramaGroups, panoramaViewerSettings, canShow, null).then(photos => {
        const filteredPanoramas = photos as Panorama[];
        const models: PanoramaModel[] = [];
        for (const photo of filteredPanoramas) {
          if (photo.widget) models.push(photo.widget.getModel() as PanoramaModel);
        }
        api3D?.panorama.getWalkthrough().setPanoramas(models);
        useViewer.setState({ filteredPanoramas });
      });
    } else if (!enabledPanoramaWalkthrough) {
      for (const group of panoramaGroups) {
        for (const photo of group.photos) {
          photo.widget?.hide();
        }
      }
    }
  }, [
    annotationDraft,
    panoramaViewerSettings,
    panoramaGroups,
    enabled2D,
    enabledPanoramaWalkthrough,
    api3D,
    imageViewerTab,
  ]);

  // Handle 3D click events
  useEffect(() => {
    const handleCursorTap: CursorListener = (position, model) => {
      // Update sort point for image viewer
      if (!photo2DViewerSettings.lockSorting) {
        setImageViewerSettings({ sortPoint: position }, 'photo2D');
      }
      if (
        !panoramaViewerSettings.lockSorting &&
        !enabledPanoramaWalkthrough &&
        panoramaViewerSettings.sortMode === 'distance to clicked point'
      ) {
        setImageViewerSettings({ sortPoint: position }, 'panorama');
      }

      // Click a camera
      if (model instanceof ModelNode) {
        model = model.getModel();
      }
      if (model instanceof PhotoCamera && !enabledPanoramaWalkthrough) {
        for (const group of photo2DGroups) {
          const photo = group.photos.find(photo => photo.widget?.getModel() === model);
          if (photo) {
            setImageViewerTab('photo2D');
            setTargetPhoto(photo);
            break;
          }
        }
      }
      if (model instanceof PanoramaModel && !enabledPanoramaWalkthrough) {
        for (const group of panoramaGroups) {
          const photo = group.photos.find(photo => photo.widget?.getModel() === model);
          if (photo) {
            setImageViewerTab('panorama');
            setTargetPhoto(photo);
            startPanoramaWalkthrough(photo as Panorama);
          }
        }
      }
      if (model instanceof Sketch && !enabledDraw3D) {
        for (const group of annotationGroups) {
          const annotation = group.annotations.find(query => query.sketch3D?.getModel() === model);
          if (annotation) {
            startUpdateAnnotation(annotation.metadata, annotation.template);
            break;
          }
        }
        for (const measurement of measurements) {
          if (measurement.sketch === model) {
            setTargetMeasurement(measurement);
            break;
          }
        }
      }
    };

    addViewerEventListener('onCursorTap', handleCursorTap);
    return () => {
      removeViewerEventListener('onCursorTap', handleCursorTap);
    };
  }, [
    annotationGroups,
    api3D,
    enabledPanoramaWalkthrough,
    enabledDraw3D,
    measurements,
    panoramaGroups,
    panoramaViewerSettings,
    photo2DGroups,
    photo2DViewerSettings,
  ]);

  // Handle 2D click events
  useEffect(() => {
    const handleCursorTap = (sketch: Sketch2) => {
      for (const group of annotationGroups) {
        const annotation = group.annotations.find(query => query.sketch2D === sketch);
        if (annotation) {
          startUpdateAnnotation(annotation.metadata, annotation.template);
        }
      }
    };

    addViewerEventListener('onSketch2DTap', handleCursorTap);
    return () => {
      removeViewerEventListener('onSketch2DTap', handleCursorTap);
    };
  }, [annotationGroups]);

  // Handle hover events
  useEffect(() => {
    const handleCursorHover: CursorListener = (_, model) => {
      if (model instanceof ModelNode) {
        model = model.getModel();
      }
      for (const group of photo2DGroups) {
        for (const photo of group.photos) {
          if (photo.widget) {
            const camera = photo.widget.getModel() as PhotoCamera;
            if (photo === targetPhoto) {
              camera.setColor(new Color(0xf47c31));
            } else if (model === camera) {
              camera.setColor(new Color(0xffffff));
            } else {
              camera.setColor(new Color(0x00ff00));
            }
          }
        }
      }
      for (const group of panoramaGroups) {
        for (const photo of group.photos) {
          if (photo.widget) {
            const camera = photo.widget.getModel() as PanoramaModel;
            if (model === camera) {
              camera.setColor(new Color(0x80b0ff));
            } else {
              camera.setColor(new Color(0xffffff));
            }
          }
        }
      }
    };

    addViewerEventListener('onCursorHover', handleCursorHover);
    return () => {
      removeViewerEventListener('onCursorHover', handleCursorHover);
    };
  }, [photo2DGroups, panoramaGroups, targetPhoto]);

  // Update the color of the target photo widget
  useEffect(() => {
    for (const group of photo2DGroups) {
      for (const photo of group.photos) {
        if (photo.widget) {
          const camera = photo.widget.getModel() as PhotoCamera;
          if (photo === targetPhoto) {
            camera.setColor(new Color(0xf47c31));
          } else {
            camera.setColor(new Color(0x00ff00));
          }
        }
      }
    }
  }, [photo2DGroups, targetPhoto]);

  // Handle keyboard shortcut events
  useEffect(() => {
    const toggle = (key: string) => {
      if (key === hotkeys.lockSorting) {
        setImageViewerSettings(prev => ({ lockSorting: !prev.lockSorting }), imageViewerTab);
      } else if (key === hotkeys.showCameras) {
        setImageViewerSettings(prev => ({ showCameras: !prev.showCameras }), imageViewerTab);
      } else if (key === hotkeys.autoOrient3D) {
        setImageViewerSettings(prev => ({ autoOrient3D: !prev.autoOrient3D }), imageViewerTab);
      }
    };

    let shiftKeyPressed = false;
    let holdModifier = false;
    const hotkeyDownListener = (event: KeyboardEvent) => {
      if (!event.repeat) {
        if (event.key === 'Shift') {
          shiftKeyPressed = true;
        } else if (shiftKeyPressed) {
          holdModifier = true;
        }
        toggle(event.key.toUpperCase());
      }
    };
    const hotkeyUpListener = (event: KeyboardEvent) => {
      if (!event.repeat) {
        if (event.key === 'Shift') {
          shiftKeyPressed = false;
        } else if (shiftKeyPressed && holdModifier) {
          toggle(event.key.toUpperCase());
          holdModifier = false;
        }
      }
    };
    window.addEventListener('keydown', hotkeyDownListener);
    window.addEventListener('keyup', hotkeyUpListener);

    return () => {
      window.removeEventListener('keydown', hotkeyDownListener);
      window.removeEventListener('keyup', hotkeyUpListener);
    };
  }, [imageViewerTab, hotkeys]);

  // Handle camera motion finish events
  useEffect(() => {
    // Ignore first camera motion finish event
    const cb: CameraMotionCallback = (position, rotation, params) => {
      if (!initialCameraCallback.current) {
        persist.set('cameraPosition', position);
        persist.set('cameraRotation', rotation);
        persist.set('orthoMatrix', params);
        persist.url.refreshURL();
      }
      initialCameraCallback.current = false;

      // Update sort point for image viewer
      if (
        !panoramaViewerSettings.lockSorting &&
        panoramaViewerSettings.sortPoint.distanceToSquared(position) &&
        panoramaViewerSettings.sortMode === 'distance to camera'
      ) {
        setImageViewerSettings({ sortPoint: position }, 'panorama');
      }
    };
    addViewerEventListener('onCameraMotionFinished', cb);
    return () => removeViewerEventListener('onCameraMotionFinished', cb);
  }, [
    enabledPanoramaWalkthrough,
    panoramaViewerSettings.lockSorting,
    panoramaViewerSettings.sortMode,
    panoramaViewerSettings.sortPoint,
  ]);

  return (
    <div
      className={cn(
        'h-full',
        'flex',
        'flex-1',
        isShowingLeftSideBarI && 'ml-400px',
        isShowingRightSideBar && 'mr-400px',
      )}
      style={style}
    >
      <SplitView
        bottomPane={<Viewer2D ref={setViewerAPI2D} />}
        enabledSplit={
          (enabled2D && !enabledPanoramaWalkthrough && imageViewerTab !== 'panorama') ||
          (targetPhoto !== null && targetProcess?.images.get(targetPhoto.id) !== undefined)
        }
        topPane={<Viewer3D />}
      />
      {enabled2D && !enabledDraw2D && <ImageViewerControls />}
      {enabledDataAttributionModal && (
        <DraggableMenu disableCloseButton y={300}>
          <DataAttributionModal />
        </DraggableMenu>
      )}
    </div>
  );
};
