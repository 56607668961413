import { LIST_MODELS_BY_ACCOUNT_ID } from '@/graphql/queries';
import { request } from '@/graphql/request';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

const getQueryKey = () => ['LIST_MODELS_BY_ACCOUNT_ID'];

export const useModels = () => {
  const response = useQuery({
    queryFn: () => request(LIST_MODELS_BY_ACCOUNT_ID),
    queryKey: getQueryKey(),
  });

  const models = useMemo(() => {
    return response.data?.findTrainModelsByAccountId;
  }, [response.data]);

  return models ?? [];
};

useModels.getQueryKey = getQueryKey;
