import { cn } from '@/utils/classname';
import { Button, CheckBox, Input, Modal, Toast } from '@skand/ui';
import { useEffect, useMemo, useState } from 'react';
import { useAnnotationTemplates } from '@/hooks/useAnnotationTemplates';
import { useViewer } from '@/stores/viewer';
import { useMutation } from '@tanstack/react-query';
import { TriggerTrainMutationVariables } from '@/graphql/codegen/graphql';
import { START_MODEL_TRAINING } from '@/graphql/mutations';
import { request } from '@/graphql/request';
import { useModels } from '@/hooks/useModels';

export interface ModelTrainingProcessProps {
  active: boolean;
  setActive: (value: boolean) => void;
}

export const ModelTrainingProcess = ({ active, setActive }: ModelTrainingProcessProps) => {
  const [step, setStep] = useState(1);
  const [trainingStatus, setTrainingStatus] = useState<'idle' | 'loading' | 'success' | 'error'>(
    'idle',
  );
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);

  const [trainingAnnotationProcessId, setTrainingAnnotationProcessId] = useState<string | null>(
    null,
  );
  const [testingAnnotationProcessId, setTestingAnnotationProcessId] = useState<string | null>(null);
  const [trainingAnnotationTemplateId, setTrainingAnnotationTemplateId] = useState<string | null>(
    null,
  );
  const [trainingAnnotationTemplateFieldIds, setTrainingAnnotationTemplateFieldIds] = useState<
    string[]
  >([]);
  const [trainingModelName, setTrainingModelName] = useState<string | null>(null);

  const { templates } = useAnnotationTemplates();
  const models = useModels();
  const processes = useViewer(state => state.processes);
  const annotationProcesses = useMemo(
    () => processes.filter(process => process.kind === 'annotation'),
    [processes],
  );

  const startModelTraining = useMutation({
    mutationFn: (variables: TriggerTrainMutationVariables) =>
      request(START_MODEL_TRAINING, variables),
    onSuccess: data => {
      setTrainingStatus('success');
      setSuccessMessage('Model training started successfully. Task ID: ' + data?.triggerTrain);
    },
    onError: error => {
      setTrainingStatus('error');
      setErrorMessage((error as Error).message);
    },
  });

  const isDisabled = () => {
    if (step === 1) {
      if (!trainingAnnotationTemplateId) return true;
      if (!trainingAnnotationTemplateFieldIds.length) return true;
      return false;
    } else if (step === 2) {
      if (!trainingAnnotationProcessId) return true;
      return false;
    } else if (step === 3) {
      if (!testingAnnotationProcessId) return true;
      return false;
    }
  };

  useEffect(() => {
    if (!active) {
      setStep(1);
      setTrainingAnnotationTemplateId(null);
      setTrainingAnnotationTemplateFieldIds([]);
      setTrainingAnnotationProcessId(null);
      setTestingAnnotationProcessId(null);
      setTrainingModelName(null);
      setTrainingStatus('idle');
      setErrorMessage(null);
      setSuccessMessage(null);
    }
  }, [active]);

  const renderDescription = () => {
    switch (step) {
      case 1: {
        if (!trainingAnnotationTemplateId) {
          return 'Select an Annotation Template';
        }

        const selectedTemplate = templates.find(
          template => template.id === trainingAnnotationTemplateId,
        );
        return (
          <>
            <div>Select Fields from the Annotation Template</div>
            <div className="mt-3 flex items-center gap-2">
              <div className="text-neutral-800 typo-text-small">
                {' '}
                Annotation Template: {selectedTemplate?.name}
              </div>
              <Button
                className="cursor-pointer typo-text-small"
                onClick={() => setTrainingAnnotationTemplateId(null)}
                size="s"
              >
                Change Template
              </Button>
            </div>
          </>
        );
      }

      case 2:
        return 'Select Training Annotation Process';
      case 3:
        return 'Select Testing Annotation Process';
      case 4:
        return 'Select Model to Train';
      case 5:
        return 'Proceed with Training the Model Using the Selected Data';
    }
  };

  const SummaryItem = ({ label, value }: { label: string; value: string }) => (
    <div className="px-3 py-1">
      <div className="text-neutral-600 typo-text-small">{label}</div>
      <div className="mt-1 text-neutral-800">{value}</div>
    </div>
  );

  const prepareContent = () => {
    switch (step) {
      case 1: {
        if (!trainingAnnotationTemplateId) {
          return templates.map(template => (
            <div
              className={cn(
                'text-left py-2 px-3 hover:bg-[#F5F3F6] cursor-pointer typo-text-small text-neutral-800',
              )}
              key={template.id}
              onClick={() => {
                setTrainingAnnotationTemplateId(template.id);
              }}
            >
              {template.name}
            </div>
          ));
        }

        const selectedTemplate = templates.find(
          template => template.id === trainingAnnotationTemplateId,
        );
        if (!selectedTemplate) return <div>No template selected</div>;

        return selectedTemplate.fields?.map(field => (
          <div
            className={cn(
              'flex items-center text-left py-2 px-3 hover:bg-[#F5F3F6] cursor-pointer typo-text-small text-neutral-800',
            )}
            key={field?.id}
            onClick={() => {
              setTrainingAnnotationTemplateFieldIds([field?.id as string]);
            }}
          >
            <CheckBox
              checked={trainingAnnotationTemplateFieldIds.includes(field?.id as string)}
              className="mr-2 cursor-pointer"
            />
            {field?.name}
          </div>
        ));
      }
      case 2:
        return annotationProcesses.map(process => (
          <div
            className={cn(
              'flex items-center text-left py-2 px-3 hover:bg-[#F5F3F6] cursor-pointer typo-text-small text-neutral-800',
            )}
            key={process.id}
            onClick={() => {
              setTrainingAnnotationProcessId(process.id);
            }}
          >
            <CheckBox
              checked={trainingAnnotationProcessId === process.id}
              className="mr-2 cursor-pointer"
            />
            {process?.name}
          </div>
        ));
      case 3:
        return annotationProcesses.map(process => (
          <div
            className={cn(
              'flex items-center text-left py-2 px-3 hover:bg-[#F5F3F6] cursor-pointer typo-text-small text-neutral-800',
            )}
            key={process.id}
            onClick={() => {
              setTestingAnnotationProcessId(process.id);
            }}
          >
            <CheckBox
              checked={testingAnnotationProcessId === process?.id}
              className="mr-2 cursor-pointer"
            />
            {process?.name}
          </div>
        ));
      case 4: {
        return (
          <>
            <div className="flex flex-col gap-4 px-4 py-4">
              <Input
                label="Create a New Model"
                onChange={value => setTrainingModelName(value)}
                required
                tail={<div className={cn('i-skand-link', 'color-neutral-400', 'text-4', 'ml-1')} />}
                value={trainingModelName ?? ''}
              />
            </div>

            {models &&
              models.map(
                (modelName: string | null) =>
                  modelName && (
                    <div
                      className={cn(
                        'text-left py-2 px-3 hover:bg-[#F5F3F6] cursor-pointer typo-text-small text-neutral-800',
                      )}
                      key={modelName}
                      onClick={() => {
                        setTrainingModelName(modelName);
                        handleSubmit();
                      }}
                    >
                      {modelName}
                    </div>
                  ),
              )}
          </>
        );
      }
      case 5: {
        const selectedTemplate = templates.find(
          template => template.id === trainingAnnotationTemplateId,
        );
        const selectedFields = selectedTemplate?.fields?.filter(field =>
          trainingAnnotationTemplateFieldIds.includes(field?.id as string),
        );
        const trainingProcess = annotationProcesses.find(
          process => process.id === trainingAnnotationProcessId,
        );
        const testingProcess = annotationProcesses.find(
          process => process.id === testingAnnotationProcessId,
        );

        return (
          <div className="space-y-4">
            <SummaryItem
              label="Training Annotation Template"
              value={selectedTemplate?.name || 'Not selected'}
            />
            <SummaryItem
              label="Training Fields"
              value={selectedFields?.map(field => field?.name).join(', ') || 'None selected'}
            />
            <SummaryItem
              label="Training Annotation Process"
              value={trainingProcess?.name || 'Not selected'}
            />
            <SummaryItem
              label="Testing Annotation Process"
              value={testingProcess?.name || 'Not selected'}
            />
            <SummaryItem label="Model Name" value={trainingModelName || 'Not entered'} />
          </div>
        );
      }
    }
  };

  const handleCancel = () => {
    switch (step) {
      case 1:
        setActive(false);
        break;
      case 2:
        setStep(1);
        break;
      case 3:
        setStep(2);
        break;
      case 4:
        setTrainingModelName(null);
        setStep(3);
        break;
      case 5:
        setTrainingStatus('idle');
        setStep(4);
        break;
    }
  };

  const handleSubmit = () => {
    if (step === 5) {
      setTrainingStatus('loading');
      startModelTraining.mutate({
        request: {
          annotationTemplateId: trainingAnnotationTemplateId,
          labelFieldId: trainingAnnotationTemplateFieldIds[0],
          trainAnnotationProcessId: trainingAnnotationProcessId,
          testAnnotationProcessId: testingAnnotationProcessId,
          modelName: trainingModelName,
        },
      });
    } else {
      setStep(prev => prev + 1);
    }
  };

  if (active)
    return (
      <Modal>
        <div className="flex items-center justify-between">
          <p className="color-neutral-800 typo-text-l">Model Training</p>
          <div
            className={cn(
              'text-12px i-skand-close transform-rotate-90 text-neutral-400 hover:text-neutral-600 cursor-pointer',
            )}
            onClick={() => setActive(false)}
          />
        </div>
        <div className={cn('color-neutral-800 typo-text-m')}>{renderDescription()}</div>

        <div
          className={cn(
            'h-[444px] flex flex-col rounded-md gap-[10px] py-3 border-[1px] border-solid border-neutral-400 overflow-y-auto',
          )}
        >
          {prepareContent()}
        </div>

        <div className="flex justify-end">
          {trainingStatus === 'success' && <Toast message={successMessage} type="info" />}
          {trainingStatus === 'error' && <Toast message={errorMessage} type="warn" />}
          {trainingStatus === 'loading' && (
            <Toast message="Starting model training..." type="info" />
          )}
        </div>

        <div className={cn('flex', 'justify-end', 'gap-3')}>
          <Button className={cn('cursor-pointer flex-1')} onClick={handleCancel} size="s">
            {step === 1 ? 'Cancel' : 'Back'}
          </Button>
          <Button
            className={cn(
              'flex-1',
              isDisabled() || trainingStatus === 'loading'
                ? 'cursor-not-allowed'
                : 'cursor-pointer',
            )}
            disabled={isDisabled() || trainingStatus === 'loading'}
            onClick={() => (trainingStatus === 'success' ? setActive(false) : handleSubmit())}
            primary
            size="s"
          >
            {step === 5
              ? trainingStatus === 'loading'
                ? 'Starting...'
                : trainingStatus === 'success'
                ? 'Close'
                : 'Start Training'
              : 'Next'}
          </Button>
        </div>
      </Modal>
    );
};
